<template>
  <div class="star-container">
    <h>
      <span class="name" v-text="'精选'" />
    </h>
    <div class="main">
      <img src="./img/wait.png" />
      <h2>即将推出，敬请期待！</h2>
    </div>
  </div>
</template>
<script>
import h from "@/components/base/header";
export default {
  components: {
    h
  }
};
</script>
<style lang="stylus" scoped>
.star-container
  text-align center
  .main
    margin-top 100px
  h2
    padding 20px 0
    font-size 16px
    color #000
</style>
